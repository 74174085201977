.dialogOverlay {
    background: rgb(0, 0, 0, .15);
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.dialogContent {
    background-color: var(--color-white);
    border-radius: 6px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    max-width: 450px;
    max-height: 85vh;
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    outline: none;
}

.dialogContent:focus {
    outline: none;
}

@keyframes overlayShow {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes contentShow {
    from {
        opacity: 0;
        transform: translate(-50%, -48%) scale(0.96);
    }

    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

.infoText {
    color: var(--color-gray);
    margin: 0;
    padding: 0;
}

.form {
    display: flex;
    gap: 12px;
}

.calendarWrapper {
    background: var(--color-white);
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 4px;
    -webkit-appearance: none;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    -webkit-box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
}

.wrapper {
    /* background: var(--color-lilac-dark); */
    padding: 16px;
    border-radius: 4px;
    display: inline-flex;
}

@media (max-width: 768px) {
    .calendarCard {
        margin-bottom: 0;
    }

    .infoText {
        padding: 16px;
        box-shadow: 0 -4px 16px rgba(0, 0, 0, .09);
        -webkit-box-shadow: 0 -4px 16px rgba(0, 0, 0, .09);
        z-index: 1;
    }

    .form {
        flex-direction: column;
    }

    .calendarContainer {
        max-height: 70vh;
        min-height: 400px;
        overflow-y: auto;
        padding: 16px;
    }

    .calendarWrapper {
        padding: 0;
        gap: 0;
    }

    .calendarWrapper {
        width: 98vw;
        max-width: 450px;
    }
}

.submit {
    all: unset;
    background: var(--color-gray);
    color: var(--color-white);
    border-radius: 8px;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 49px;
    padding: 0 12px;
    padding-right: 24px;
    cursor: pointer;
    min-width: 101px;
}

.submit svg {
    width: 18px;
    height: 18px;
}

.errorForm {
    gap: 64px;
}

.errorForm button.submit {
    padding: 0 24px;
}

.errorMessageTitle {
    font-size: 20px;
    color: var(--color-white);
}

.errorMessageDescription {
    font-size: 16px;
    color: var(--color-lilac);
}

@media (max-width: 768px) {
    .errorForm {
        gap: 12px;
        max-width: 220px;
    }

    .errorMessage {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .errorMessageDescription {
        margin-bottom: 24px;
    }
}