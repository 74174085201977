import classNames from "classnames";
import styles from "./style.module.css";
import { SpinnerIcon } from "../Icon";

export function FakeWidjetInput({
    icon,
    className = "",
    label
}) {
    return (
        <label className={classNames(styles.wrapper, className)}>
            {icon && <div className={styles.icon}>
                {icon}
            </div>}
            <div className={styles.group}>
                <div className={styles.label}>{label}</div>
                <div className={styles.loader}><SpinnerIcon /> Загрузка...</div>
            </div>
        </label>
    )
}