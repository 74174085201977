import React from "react";

export function getModuleIframe(src, dateFrom, dateTo, rooms, timeSettings) {
    const url = new URL(src);

    url.searchParams.append("dateFrom", dateFrom);
    url.searchParams.append("dateTo", dateTo);
    url.searchParams.append("rooms", JSON.stringify(rooms));
    url.searchParams.append("timeSettings", JSON.stringify(timeSettings));

    const module = React.createElement("iframe", {
        src: url,
        className: "lv-booking-module",
        id: "lv-booking-module"
    });

    return module;
}